import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import MsTeams from '../components/integrations/MsTeams';
import AmberScore from "../components/reusable/AmberScore"

const msteams = () => (
  <Layout extra_class={'mg-top-80'}>
    <SEOComponent title="Amber by inFeedo | Integrations" />
    <MsTeams/>
    <AmberScore/>
  </Layout>
)

export default msteams;
