import React, { Component } from 'react';
import { isMobileDevice } from "../../helper/isMobileDevice";
import classnames from 'classnames';
//assets
import ms_teams_web from '../../../static/images/integrations/ms_teams_web.png';
import msteams_mobile from '../../../static/images/integrations/msteams_mobile.png';
import msteams_logo from '../../../static/images/integrations/msteams_logo.png';
import config from '../../../config';

export default class MsTeams extends Component {
    
    state = {
        is_mobile: false
    }

    componentDidMount(){
        if (isMobileDevice()) {
            this.setState({
                is_mobile: true
            })
        }
    }

    openMsTeams = () => {
        window.open(`https://login.microsoftonline.com/common/adminconsent?client_id=c6eefc55-594e-461e-9016-c70661a0e943&state=12345&redirect_uri=${config.url}app_integrations/ms_teams/consent`)
    }

    openMsTeamsVideo = () => {
        window.open('https://www.loom.com/share/9ff48674c92d42658e76dd0009761071');
    }

    render() {
        const {is_mobile} = this.state;
        return (
            <div className={classnames('columns flex-wrap no-margin-left integrations', {'is-fullwidth': is_mobile})}>
                <div className={classnames('column is-12 columns no-padding-integrations no-margin-left center-align-items', {'no-margin': is_mobile})}>
                    <div className={classnames('column is-5 columns flex-wrap no-margin-left no-padding-left', {'no-margin no-padding': is_mobile})}>
                        <div className={classnames("is-12 column mg-top-n-30", {'pd-left-70': !is_mobile}, {'has-text-centered no-padding-left no-padding' : is_mobile})}>
                            <p className={classnames("has-text-weight-bold line-height-1 mg-bottom-20 integrations-heading-color", {'font-48 is-pulled-left': !is_mobile}, {'is-3 title': is_mobile})}>
                                Connect to your everyday tool... <br/>Microsoft Teams!
                            </p>
                            <p className={classnames("is-pulled-left integrations-sub-text-color", {'font-18': !is_mobile}, {'font-14 mg-left-right-10': is_mobile})}>
                                Install Amber's Microsoft Teams application to seamlessly integrate with the workflow of your employees. Make it easy for your people to share feedback on the tools they use everyday.
                            </p>
                        </div>
                        <div className={classnames("is-11 column columns", {'flex-wrap no-padding no-margin has-text-centered': is_mobile}, {'pd-left-70 has-text-left': !is_mobile})}>
                            <button className={classnames('button has-text-weight-bold msteams-button-color', {'column is-2 width-170 mg-left-15': !is_mobile, 'is-inline-block width-190 mg-top-10': is_mobile})} onClick={this.openMsTeams}>
                                <img className="mg-right-5" width="20" height="20" src={msteams_logo} alt="Bottom"/> 
                                <span>Add to Teams</span>
                            </button>
                            <button className={classnames("button has-text-weight-bold", {'mg-left-10 column is-2 width-170': !is_mobile}, {'mg-top-10 is-inline-block width-190 mg-bottom-20': is_mobile})} onClick={this.openMsTeamsVideo}>
                                Watch Demo
                            </button>
                        </div>
                    </div>
                    <div className="column is-7 no-padding-integrations">
                        <img className={classnames("mg-bottom-30", {'is-pulled-right': !is_mobile}, {'is-pulled-left': is_mobile})} src={is_mobile ? msteams_mobile : ms_teams_web} alt="Slack"/>
                    </div>
                </div>
            </div>
        )
    }
}
